import {Grid, MenuItem} from "@mui/material";
import TextField from "../../../components/Formik/TextField";
import Select from "../../../components/Formik/Select";
import Checkbox from "../../../components/Formik/Checkbox";
import Radio from "../../../components/Formik/Radio";
import DateNative from "../../../components/Formik/Date/Native";
import {useFormikContext} from "formik";
import DisableTextField from "../../../components/Mui/DisableTextField";
import NumberField from "../../../components/Formik/NumberField";
import {run} from "../../../utils/services";
import {hydraService} from "../../../utils/hydra";
import {useCallback, useEffect, useMemo, useState} from "react";
import Loader from "../../Loader";
import {strawService} from "../../../services/straw.service";
import {useStrawTicketContext} from "../../../context/StrawTicketContext";
import {catalogService} from "../../../services/catalog.service";
import ManufacturedBallField from "./Fabrication/ManufacturedBallField";
import HumidityRateField from "./Fabrication/HumidityRateField";
import PebbleWeightField from "./Fabrication/PebbleWeightField";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {ROLES} from "../../../constants/roles";
import RefusedMessage from "./Fabrication/RefusedMessage";

let cache = {};

const FabricationPage = ({name, catalogArticle, catalogQualityTypes}) => {
    const {values, setFieldValue, setValues} = useFormikContext();
    const {memberSelected, contractSelected, hasContractChange, setHasContractChange} = useStrawTicketContext()
    const [loading, setLoading] = useState(true);
    const [catalog, setCatalog] = useState({});
    const article = catalogArticle.find(el => el['@id'] === values.article);

    const populate = (values = []) => {
        let [uniformities, ballWeights, allColorManufacturings, pollutions, presentations, loadingTimes, humidities, pebbles, productionLines] = values;
        uniformities = hydraService.getMembers(uniformities);
        ballWeights = hydraService.getMembers(ballWeights);
        let colorManufacturings = hydraService.getMembers(allColorManufacturings).filter(el => el.article['@id'] === article['@id']);
        pollutions = hydraService.getMembers(pollutions);
        presentations = hydraService.getMembers(presentations);
        loadingTimes = hydraService.getMembers(loadingTimes);
        humidities = hydraService.getMembers(humidities);
        pebbles = hydraService.getMembers(pebbles);
        setCatalog({
            uniformities,
            ballWeights,
            colorManufacturings,
            pollutions,
            presentations,
            loadingTimes,
            humidities,
            pebbles,
            productionLines
        });
    }

    useEffect(() => {
        let {harvestYear: year} = values;
        year = contractSelected?.year ?? year;
        if (year in cache) {
            populate(cache[year]);
            changeFabricationByYear(cache[year]);
            setHasContractChange(false); // IMPORTANT
            setLoading(false);
            return;
        }
        Promise.all([
            run(strawService.getAllStrawUniformities, {pagination: false, year: year}),
            run(strawService.getAllStrawBallWeights, {pagination: false, year: year}),
            run(strawService.getAllStrawColorManufacturings, {pagination: false, year}),
            run(strawService.getAllStrawPollutions, {pagination: false, year: year}),
            run(strawService.getAllStrawPresentations, {pagination: false, year: year}),
            run(strawService.getAllStrawLoadingTimes, {pagination: false, year: year}),
            run(strawService.getAllStrawHumidities, {pagination: false, year: year}),
            run(strawService.getAllStrawPebbles, {pagination: false, year: year}),
            run(catalogService.getAllProductionLine),
        ]).then(results => {
            cache[year] = results.map(el => el.responseData ?? el);
            populate(cache[year]);
            changeFabricationByYear(cache[year]);
            setHasContractChange(false); // IMPORTANT
            setLoading(false);
        });

        return () => null;
    }, [contractSelected]);

    const changeFabricationByYear = (newCatalogValues = []) => {
        if (hasContractChange === false) {
            return;
        }

        const {
            grossWeight,
            tareWeight,
            fabrication: {
                pebbleWeight,
                manufacturedBall,
                humidityRate,
                scaleInfos: {colorManufacturing, pollution, presentation, uniformity, loadingTime}
            }
        } = values;
        const [uniformities, ballWeights, colorManufacturings, pollutions, presentations, loadingTimes, humidities, pebbles, productionLines] = newCatalogValues;

        // Finder
        // On recherche le nouveau poids de balle
        const deliveredWeight = Math.max(+grossWeight - +tareWeight, 0).toFixed(2);
        const workedWeight = Math.max(+deliveredWeight * 1000 - pebbleWeight, 0).toFixed(2);
        const calculateBallWeight = manufacturedBall > 0 ? +workedWeight / manufacturedBall : null;
        const newBallWeightId = hydraService.getMembers(ballWeights).find(el => +el.startScale <= calculateBallWeight && +el.endScale >= calculateBallWeight) ?? null;
        // On recherche le nouveau cailloux
        const calculatePebbleRate = pebbleWeight !== null && deliveredWeight > 0 ? +(pebbleWeight / (deliveredWeight * 1000) * 100).toFixed(2) : null;
        const newPebbleId = calculatePebbleRate ? hydraService.getMembers(pebbles).find(el => +el.startScale <= calculatePebbleRate && +el.endScale >= calculatePebbleRate) : null;
        // On recherche le nouveau taux d'humidité
        const newHumidityId = humidityRate ? hydraService.getMembers(humidities).find(el => +el.startScale <= humidityRate && +el.endScale >= humidityRate) : null;

        // Code
        const newColorManufacturingId = hydraService.getMembers(colorManufacturings).find(el => el.code === colorManufacturing.code && el.article['@id'] === article['@id']);
        const newUniformityId = hydraService.getMembers(uniformities).find(el => el.code === uniformity.code);
        const newPresentationId = hydraService.getMembers(presentations).find(el => el.code === presentation.code);
        const newPollutionId = hydraService.getMembers(pollutions).find(el => el.code === pollution.code);
        const newLoadingTime = hydraService.getMembers(loadingTimes).find(el => el.label === loadingTime.label);

        setValues(prevValues => ({
            ...prevValues,
            fabrication: {
                ...prevValues.fabrication,
                pebble: hydraService.getIriFromItem(newPebbleId) ?? null,
                humidity: hydraService.getIriFromItem(newHumidityId) ?? null,
                ballWeight: hydraService.getIriFromItem(newBallWeightId) ?? null,
                colorManufacturing: hydraService.getIriFromItem(newColorManufacturingId) ?? null,
                uniformity: hydraService.getIriFromItem(newUniformityId) ?? null,
                presentation: hydraService.getIriFromItem(newPresentationId) ?? null,
                pollution: hydraService.getIriFromItem(newPollutionId) ?? null,
                loadingTime: hydraService.getIriFromItem(newLoadingTime) ?? null
            }
        }))
    }

    const scaleFinder = (scale, id) => {
        return scale?.find(el => el['@id'] === id);
    }

    const calculateVariability = useMemo(() => {
        const total = Number(scaleFinder(catalog.ballWeights, values[name].ballWeight)?.amount ?? 0) +
            Number(scaleFinder(catalog.uniformities, values[name].uniformity)?.amount ?? 0) +
            Number(scaleFinder(catalog.pebbles, values[name].pebble)?.amount ?? 0) +
            Number(scaleFinder(catalog.presentations, values[name].presentation)?.amount ?? 0) +
            Number(scaleFinder(catalog.loadingTimes, values[name].loadingTime)?.amount ?? 0);
        return total.toFixed(2);
    }, [
        catalog,
        values[name].ballWeight,
        values[name].uniformity,
        values[name].pebble,
        values[name].presentation,
        values[name].loadingTime,
    ]);

    const toggleCheckboxPollutionAndHumidity = useCallback((id) => {
        const pollutionSelected = catalog.pollutions.find(el => el['@id'] === id);
        const humiditiesSelected = catalog.humidities.find(el => el['@id'] === id);
        if (pollutionSelected) {
            setFieldValue(`${name}.hasNoSupplement`, pollutionSelected.label === 'Oui');
        }
        if (humiditiesSelected) {
            setFieldValue(`${name}.hasNoSupplement`, humiditiesSelected.hasPriceSupplement);
        }
    }, [catalog, values[name].pollution, values[name].humidity]);

    return (
        loading ? <Loader/> :
            <>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid item xs={12}>
                        <DisableTextField
                            label={'Adhérent'}
                            value={`${memberSelected?.company ?? ''}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'N° ticket'}
                            value={`${values?.number ?? ''}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'Contrat'}
                            value={contractSelected ? `${contractSelected?.year} - ${contractSelected?.declaration?.contractType} - ${contractSelected?.farmingType}` : ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}/>
                    <Grid item xs={12}>
                        <DisableTextField
                            label={'Article'}
                            value={`${article?.label ?? ''}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'A livrer'}
                            value={`${values?.toDeliver ?? ''}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'Livré'}
                            value={`${values?.delivered ?? ''}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'Reste à livrer'}
                            value={`${values?.stillDelivered ?? ''}`}
                        />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} sx={{mt: 3}}
                      className="Grid-container">
                    <Grid item xs={12} md={12}>
                        <Radio label={''}
                               name={`${name}.qualityType`}
                               options={catalogQualityTypes.map(el => ({value: el, label: el}))}
                               row
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.productionLine`}
                            label={'Ligne de fabrication'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.productionLines.map((el, i) => <MenuItem key={i}
                                                                              value={el}>{el}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DateNative
                            name={`${name}.fabricationDate`}
                            label={'Date de fabrication'}
                            nullIfEmpty
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ManufacturedBallField catalog={catalog} name={name} loading={loading}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.ballWeight`}
                            label={'Poids des balles'}
                            nullIfEmpty
                            disabled={true}
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.ballWeights.map((el, i) => <MenuItem key={i}
                                                                          value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.uniformity`}
                            label={'Homogénéité'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.uniformities.map((el, i) => <MenuItem key={i}
                                                                           value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {values.fabrication.fabricationDate !== null && (
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <Checkbox name={`${name}.isFabricationValidate`}
                                          label={'Rendre visible l\'onglet fabrication pour l\'adhérent'}
                                />
                            </ProtectedComponent>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.pebble`}
                            label={'Cailloux ou terre'}
                            nullIfEmpty
                            disabled={true}
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.pebbles.map((el, i) => <MenuItem key={i}
                                                                      value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.humidity`}
                            label={'Humidité'}
                            callback={toggleCheckboxPollutionAndHumidity}
                            nullIfEmpty
                            disabled={true}
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.humidities.map((el, i) => <MenuItem key={i}
                                                                         value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.colorManufacturing`}
                            label={'Couleur'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.colorManufacturings.map((el, i) => <MenuItem
                                key={i} value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.pollution`}
                            label={'Pollution'}
                            callback={toggleCheckboxPollutionAndHumidity}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.pollutions.map((el, i) => <MenuItem key={i}
                                                                         value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.presentation`}
                            label={'Présentation'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.presentations.map((el, i) => <MenuItem
                                key={i} value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            name={`${name}.loadingTime`}
                            label={'Temps de chargement'}
                            nullIfEmpty
                        >
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.loadingTimes.map((el, i) => <MenuItem key={i}
                                                                           value={el['@id']}>{el.label}</MenuItem>)}
                        </Select>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <NumberField
                            asString
                            name={`${name}.averageColorimetry`}
                            label={'Colorimétrie moyenne'}
                            nullIfEmpty
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PebbleWeightField catalog={catalog} name={name} loading={loading}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <HumidityRateField catalog={catalog} name={name} loading={loading}/>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DisableTextField
                            label={'Majoration / Minoration'}
                            value={calculateVariability}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Checkbox name={`${name}.hasNoSupplement`} label={'Pas de complément'}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RefusedMessage catalog={catalog}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            name={`${name}.comment`}
                            label={'Commentaire fabrication'}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </>
    );
}

export default FabricationPage;