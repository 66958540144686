import React, {useCallback, useState} from 'react';
import _ from "../../../utils/lodash/number/number_format";
import MuiAutocompleteAsync from "../../Mui/AutocompleteAsync";
import {memberService} from "../../../services/member.service";
import {hydraService} from "../../../utils/hydra";

const MemberFilter = ({filters = {}, setFilters = () => null, request, ...otherProps}) => {
    const [value, setValue] = useState(null);
    const handlerCallback = useCallback(
        (e, value) => {
            const newFilters = value ? {...filters, page: 1, ...(value.code && {'member.code': value.code})} : _.omit(filters, 'member.code');
            setFilters(newFilters);
        },
        [setFilters, filters],
    );

    if ('member.code' in filters && (!value || value.code !== filters['member.code'])) {
        (async () => {
            const valueSelected = await memberService.getAllMembers({'code': filters['member.code']});
            setValue(hydraService.getMembers(valueSelected.responseData)[0] ?? null);
        })();
    }

    if (value && !('member.code' in filters)) {
        setValue(null);
    }

    return (
        <MuiAutocompleteAsync
            name={otherProps.name ?? 'code'}
            label={otherProps.label ?? 'Adhérent...'}
            filterName={otherProps.filterName ?? 'company'}
            request={request ?? memberService.getAllMembers}
            callback={handlerCallback}
            getOptionLabel={(option) => `${option.company} ${option.code}`}
            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
            value={value}
            callbackOnClear={() => setValue(null)}
        />
    );
};

export default MemberFilter;
