import {Link, useLocation, useNavigate} from "react-router-dom";
import {supplyInvoiceService} from "../../../services/supplyInvoice.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {Button, Button as MuiButton, Grid, Stack} from "@mui/material";
import ImportMenuDialog from "../../../feature/ImportMenuDialog";
import {useEffect, useMemo, useState} from "react";
import {useUserContext} from "../../../context/UserContext";
import GenerationDialog from "./GenerationDialog";
import ComptabilisationDialog from "../ComptabilisationDialog";
import {articleService} from "../../../services/article.service";
import {ROLES} from "../../../constants/roles";
import {hydraService} from "../../../utils/hydra";
import {run} from "../../../utils/services";
import ExportDialog from "./ExportDialog";
import {useDialog} from "../../../feature/DialogProvider";
import {toLocaleDate} from "../../../utils/i18n";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {ARTICLE_TYPE_CODES} from "../../../constants/types";
import {articleTypeService} from "../../../services/articleType.service";
import {depositService} from "../../../services/deposit.service";
import HarvestYearFilter from "../../../components/Filters/HarvestYearFilter";
import MemberFilter from "../../../components/Filters/MemberFilter";
import ArticleTypeFilter from "../../../components/Filters/ArticleTypeFilter";
import _ from "lodash"
import { catalogService } from "../../../services/catalog.service";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PrintIcon from "@mui/icons-material/Print";
import Download from "../../../components/Download";
import DownloadInvoice from "../../../components/DownloadInvoice";

const SupplyInvoices = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isGranted} = useUserContext();
    const [openDialog] = useDialog();
    const [filters, setFilters] = useState({});
    const [catalog, setCatalog] = useState({
        articles: [],
        articleTypeList: [],
        depositList: [],
        exportInvoicesTypes: []
    })
    useEffect(() => {
        Promise.all([
            run(articleService.getAllArticles, {pagination: false, 'type.code': ARTICLE_TYPE_CODES.APPROVISIONNEMENT}),
            run(articleTypeService.getAllArticleTypes, {pagination: false, 'order[label]': 'ASC'}),
            run(depositService.getAllDeposits, {pagination: false}),
            run(catalogService.getAllCatalogExportInvoicesTypes),
        ]).then((values) => {
            let [articles, articleTypeList, depositList, exportInvoicesTypes] = values.map((value) => value.responseData);
            articles = hydraService.getMembers(articles);
            articleTypeList = hydraService.getMembers(articleTypeList);
            depositList = hydraService.getMembers(depositList);
            setCatalog({articles, articleTypeList, depositList, exportInvoicesTypes});
        })
    }, []);

    const columns = [
        {field: 'number', headerName: 'N° Facture', flex: 1},
        {field: 'harvestYear', headerName: 'Année', flex: 1},
        {
            field: 'invoiceDate',
            headerName: 'Date de facture',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'member.company', headerName: 'Adhérent', flex: 1, valueGetter: (params) => params.row.member?.company},
        {
            field: 'member.managerName',
            headerName: 'Nom',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {field: 'reference', headerName: 'Ref', flex: 1},
        {
            field: 'articleType.label',
            headerName: 'Type d\'article',
            flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'amountExcludingVat', headerName: 'HT', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'amountIncludingVat', headerName: 'TTC', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'credit',
            headerName: 'Type de facture',
            flex: 1,
            valueFormatter: (params) => params.value ? 'CREDIT' : 'FACTURE',
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Imprimer',
            flex: 1,
            sortable: false,
            renderCell: ({row: {number}}) => {
                return <GridActionsCellItem
                    icon={<PrintIcon/>}
                    color={"primary"}
                    label={`Facture ${number}`}
                    onClick={() => DownloadInvoice(number)}
                />
            }
        },
    ];

    const importMenu = useMemo(() => ([
            {
                title: 'Génération',
                content: <GenerationDialog catalog={catalog} setFilters={setFilters}/>,
                roles: [ROLES.ADMIN]
            },
            {
                title: 'Comptabilisation', 
                content: <ComptabilisationDialog filters={{class: 'supply'}}/>,
                roles: [ROLES.ADMIN, ROLES.ACCOUNTANT]
            }
        ]
    ), [catalog]);

    return (
        <>
            <Grid container spacing={3} className="Grid Grid-root">
                <Grid item xs={8} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <HarvestYearFilter filters={filters}
                                           setFilters={setFilters}
                                           name={'harvestYear'}
                                           label={'Année contrat'}
                                           endYear={isGranted(ROLES.MEMBER) ? 2023 : 2021}
                                           stepYear={-1}
                                           optionNoneText={'Aucune'}
                        />
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MemberFilter filters={filters}
                                          setFilters={setFilters}
                            />
                        </ProtectedComponent>
                        <ArticleTypeFilter filters={filters} setFilters={setFilters}
                                           name={'articleType'}
                                           articleTypeList={catalog.articleTypeList}/>
                    </Stack>
                </Grid>
                <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                    <Stack spacing={1} direction={'row'}>
                        <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                            <MuiButton onClick={() => openDialog({
                                fullWidth: true,
                                maxWidth: 'md',
                                children: <ExportDialog catalog={catalog}
                                                      isGranted={isGranted}
                                                      title={'Exporter une/des facture(s) approvisionnement'}/>
                            })}>
                                Edition
                            </MuiButton>
                            <ImportMenuDialog buttonLabel={'Export'} data={importMenu}/>
                        </ProtectedComponent>
                        <ProtectedComponent acceptedRoles={['none']}> {/* CDL-794 */}
                            <Button component={Link} variant="contained" to={`${location.pathname}/add`} sx={{mb: 0}}>
                                Ajouter une facture</Button>
                        </ProtectedComponent>
                    </Stack>
                </Grid>
            </Grid>
            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={supplyInvoiceService.getAllSupplyInvoices}
                filters={filters}
                customPagination
            />
        </>
    )
}

export default SupplyInvoices;