import {Grid, Stack, Button as MuiButton, MenuItem} from "@mui/material";
import Button from "../../components/Formik/SubmitButton";
import snackBar from "../../components/SnackBar";
import * as Yup from "yup";
import {FieldArray, Form, Formik} from "formik";
import AutocompleteAsync from "../../components/Formik/AutocompleteAsync";
import DateNative from "../../components/Formik/Date/Native";
import {memberService} from "../../services/member.service";
import Select from "../../components/Formik/Select";
import {useState} from "react";
import NumberField from "../../components/Formik/NumberField";
import {hydraService} from "../../utils/hydra";
import {strawService} from "../../services/straw.service";
import moment from "moment";
import Box from "@mui/material/Box";
import ColorTable from "./colorTable";
import DownloadStream from "../../components/DownloadStream";
import {simulationService} from "../../services/simulation.service";
import _ from "lodash";
import SelectYear from "../../components/Formik/SelectYear";
import {run} from "../../utils/services";
import Deposits from "./Deposits";
import {articleYearService} from "../../services/articleYear.service";
import {vatCodeService} from "../../services/vatCode.service";

const cache = {};
let yearSelected = null;
let fromMemberId = null;
let toMemberId = null;
const Simulation = () => {
    const INITIAL_FORM_STATE = {
        year: null,
        fromMemberId: null,
        toMemberId: null,
        startOverstockDate: null,
        overStockageBonus: null,
        standardTransport: null,
        expressTransport: null,
        vatPercentDeposit: 0,
        vatPercentTransport: 0,
        articleId: null,
        deposits: [],
        sumDeposits: null,
        initialColors: [],
        colors: [],
        articleYears: [],
    }
    const FORM_VALIDATION = Yup.object().shape({});
    const [data, setData] = useState(INITIAL_FORM_STATE);
    const [catalog, setCatalog] = useState({
        articles: [],
        vatCodes: []
    });
    const [disabled, setDisabled] = useState(true);

    const getPaymentConditionsByYear = (year) => {
        onReset(year);
        if (year in cache) {
            yearSelected = year;
            populateArticle(cache[year]);
            return;
        }
        Promise.all([
            run(strawService.getAllStrawPaymentConditions, {year, pagination: false}),
            run(strawService.getAllStrawColorManufacturings, {year, pagination: false}),
            run(articleYearService.getAllArticlesYear, {year, pagination: false}),
            run(vatCodeService.getAllVatCodes, {pagination: false}),
        ]).then((values) => {
            let [paymentConditions, colors, articleYears, vatCodes] = values.map(el => el.responseData);
            cache[year] = {
                paymentConditions: hydraService.getMembers(paymentConditions),
                initialColors: hydraService.getMembers(colors),
                articleYears: hydraService.getMembers(articleYears),
                vatCodes: hydraService.getMembers(vatCodes)
            }
            yearSelected = year;
            populateArticle(cache[year]);
        });
    };

    const getArticleColors = (articleId, sumDeposits) => {
        if (!articleId) return [];
        const colors = (articleId ? cache[yearSelected].initialColors.filter(el => hydraService.getIriFromItem(el.article) === articleId) : data.initialColors);
        return colors
            .concat({ // Doit toujours être en dernière place. sinon problème au niveau du twig "templates/simulation/strawBalance/index.html.twig"
                '@id': 'no_complement',
                'code': 'no_complement',
                'label': 'Sans complément de prix',
                'amount': Number(sumDeposits ?? 0).toFixed(2),
                'valorisation': '',
            })
    };

    const getVatCode = (vatCodeId) => {
        return vatCodeId ? cache[yearSelected].vatCodes.find(el => el['@id'] === vatCodeId) : null;
    };

    const populateArticle = ({paymentConditions, vatCodes}) => {
        const articles = paymentConditions.map(el => el.article);
        setCatalog(prevState => ({...prevState, articles, vatCodes}));
    }

    const populate = (articleId) => {
        const paymentConditions = cache[yearSelected].paymentConditions.find(el => hydraService.getIriFromItem(el.article) === articleId);
        const articleYearExpressTransport = cache[yearSelected].articleYears.find(el => el.article.code === '13');
        const articleYearStandardTransport = cache[yearSelected].articleYears.find(el => el.article.code === '11');
        setData({
            ...data,
            year: yearSelected,
            fromMemberId,
            toMemberId,
            article: paymentConditions?.article,
            startOverstockDate: paymentConditions?.startOverstockDate ? moment(paymentConditions.startOverstockDate).format('YYYY-MM-DD') : null,
            overStockageBonus: paymentConditions?.amountBonusOverstock ?? null,
            standardTransport: articleYearStandardTransport?.amount ?? null,
            expressTransport: articleYearExpressTransport?.amount ?? null,
            vatPercentDeposit: paymentConditions?.vatAdvance?.rate ?? null,
            vatPercentTransport: getVatCode(articleYearExpressTransport?.vat)?.rate ?? null,
            sumDeposits: paymentConditions?.sumDeposits ?? null,
            isEmpty: _.isEmpty(paymentConditions),
            initialColors: cache[yearSelected].initialColors,
            colors: getArticleColors(articleId, paymentConditions?.sumDeposits ?? null),
            deposits: paymentConditions?.deposits ?? [],
            articleId
        });
    };

    const onReset = (year) => {
        setData({...INITIAL_FORM_STATE, year});
        setDisabled(true);
    }

    return (//loading ? <Loader/> :
        <Formik
            validateOnChange={false}
            initialValues={data}
            enableReinitialize
            validationSchema={FORM_VALIDATION}
            onSubmit={(values, {setFieldError, setSubmitting}) => {
                setSubmitting(true);
                run(simulationService.addSimulation, values, {setFieldError, setSubmitting})
                    .then((response) => {
                        DownloadStream(response.responseData, 'application/pdf', 'simulation_');
                        snackBar('Génération effectuée.');
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({resetForm, values}) => (<Form>
                <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}} className="Grid-container">
                    <Grid item xs={12} md={3}>
                        <SelectYear
                            name={'year'}
                            label={'Année'}
                            end={new Date().getFullYear() + 3}
                            reverse
                            callback={getPaymentConditionsByYear}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Select name={'articleId'} label={'Article'} callback={populate} nullIfEmpty>
                            <MenuItem value={''}><em>Aucun</em></MenuItem>
                            {catalog.articles.map((el, i) => {
                                return <MenuItem key={i} value={el['@id']}>{el.label}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AutocompleteAsync
                            name={'fromMemberId'}
                            label={'De l\'adhérent'}
                            filterName={'company'}
                            request={memberService.getAllMembers}
                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            filtersOnLoading={{'order[company]': 'ASC'}}
                            value={null}
                            callback={(value) => {fromMemberId = value ? value['@id'] : null}}
                            hydra
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AutocompleteAsync
                            name={'toMemberId'}
                            label={'A l\'adhérent'}
                            filterName={'company'}
                            request={memberService.getAllMembers}
                            getOptionLabel={(option) => `${option.company} ${option.code}`}
                            isOptionEqualToValue={(option, value) => option['@id'] === value['@id']}
                            filtersOnLoading={{'order[company]': 'DESC', 'reverse': true}}
                            value={null}
                            callback={(value) => {toMemberId = value ? value['@id'] : null}}
                            hydra
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DateNative
                            name={'startOverstockDate'}
                            label={'Date début de stockage'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberField
                            asString
                            name={'overStockageBonus'}
                            label={'Prime de surstockage'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberField
                            asString
                            name={'standardTransport'}
                            label={'Transport standard'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberField
                            asString
                            name={'expressTransport'}
                            label={'Transport express'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberField
                            asString
                            name={'vatPercentDeposit'}
                            label={'Pourcentage TVA'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <NumberField
                            asString
                            name={'vatPercentTransport'}
                            label={'Pourcentage TVA transport'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}/>
                    <FieldArray name={'deposits'} component={Deposits}/>
                    <Grid item xs={0} md={12}/>
                    {data.colors.length > 0 && <Grid item xs={8}>
                        <FieldArray name={'colors'} component={ColorTable}/>
                    </Grid>}
                </Grid>
                <Box sx={{mt: 2}}>
                    <Grid container spacing={3} className="Grid Grid-root">
                        <Grid container item xs={12} justifyContent={'flex-start'} className="Grid">
                            <Stack spacing={1} direction={'row'}>
                                <Button variant={'outlined'} disabled={!(values.year && values.articleId)}>Télécharger</Button>
                                <MuiButton variant={'contained'} onClick={() => {
                                    resetForm({values: {...INITIAL_FORM_STATE}});
                                }}>Reset</MuiButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Form>)}

        </Formik>);
}

export default Simulation;