import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {hydraService} from "../../../utils/hydra";
import {strawInvoiceService} from "../../../services/strawInvoice.service";
import {Button as MuiButton, Grid, Stack} from "@mui/material";
import {catalogService} from "../../../services/catalog.service";
import {articleService} from "../../../services/article.service";
import MuiTableAsync from "../../../components/Mui/DataGridAsync";
import {ROLES} from "../../../constants/roles";
import {useUserContext} from "../../../context/UserContext";
import GenerationDialog from "./GenerationDialog";
import ComptabilisationDialog from "../ComptabilisationDialog";
import ImportMenuDialog from "../../../feature/ImportMenuDialog";
import ProtectedComponent from "../../../feature/ProtectedComponent";
import {ARTICLE_TYPE_CODES, SENDER_MAIL} from "../../../constants/types";
import RedirectButton from "../../../components/Mui/RedirectButton";
import ExportDialog from "./ExportDialog";
import {useDialog} from "../../../feature/DialogProvider";
import _ from "../../../utils/lodash/number/number_format";
import {toLocaleDate} from "../../../utils/i18n";
import SenderMail from "../../../feature/SenderMail";
import HarvestYearFilter from "../../../components/Filters/HarvestYearFilter";
import MemberFilter from "../../../components/Filters/MemberFilter";
import {useFilterContext} from "../../../context/FilterContext";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PrintIcon from "@mui/icons-material/Print";
import Download from "../../../components/Download";
import DownloadInvoice from "../../../components/DownloadInvoice";

const StrawInvoices = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isGranted} = useUserContext();
    const {getFilterHash} = useFilterContext();
    const [catalog, setCatalog] = useState({
        invoiceTypes: [],
        articles: [],
        loading: true,
    });
    const [filters, setFilters] = useState(getFilterHash);
    const [openDialog] = useDialog();

    useEffect(() => {
        Promise.all([
            catalogService.getAllCatalogInvoicesTypes({pagination: false}),
            articleService.getAllArticles({pagination: false, 'type.code': ARTICLE_TYPE_CODES.PAILLE}),
            catalogService.getAllCatalogExportInvoicesTypes(),
        ]).then((values) => {
            let [invoiceTypes, articles, exportInvoicesTypes] = values.map(el => el.responseData);
            articles = hydraService.getMembers(articles);
            setCatalog({invoiceTypes, articles, exportInvoicesTypes, loading: false});
        })
    }, []);

    const columns = [
        {field: 'number', headerName: 'N° Facture', flex: 1},
        {field: 'harvestYear', headerName: 'Année', flex: 1},
        {
            field: 'invoiceDate',
            headerName: 'Date de facture',
            flex: 1,
            valueFormatter: (params) => toLocaleDate(params.value)
        },
        {field: 'member.company', headerName: 'Adhérent', flex: 1, valueGetter: (params) => params.row.member?.company},
        {
            field: 'member.managerName',
            headerName: 'Nom',
            flex: 1,
            valueGetter: (params) => params.row.member?.managerName
        },
        {field: 'referenceLabel', headerName: 'Ref', flex: 1},
        {
            field: 'article.label', headerName: 'Article', flex: 1,
            valueGetter: (params) => _.get(params.row, params.field)
        },
        {
            field: 'amountExcludingVat', headerName: 'HT', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'vat',
            headerName: 'Taux TVA',
            flex: 1,
            align: 'right',
            valueFormatter: (params) => {
                if (!params.value) {
                    return '';
                }
                return `${params.value.rate} %`;
            }
        },
        {
            field: 'amountIncludingVat', headerName: 'TTC', flex: 1, align: 'right',
            valueFormatter: (params) => _.numberFormat(params.value)
        },
        {
            field: 'credit',
            headerName: 'Type de facture',
            flex: 1,
            hide: true,
            valueFormatter: (params) => {
                return params.value ? 'CREDIT' : 'FACTURE';
            }
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Imprimer',
            flex: 1,
            sortable: false,
            renderCell: ({row: {number}}) => {
                return <GridActionsCellItem
                    icon={<PrintIcon/>}
                    color={"primary"}
                    label={`Facture ${number}`}
                    onClick={() => DownloadInvoice(number)}
                />;
            }
        },
    ];

    const importMenu = useMemo(() => {
        return [
            {
                title: 'Génération',
                content: <GenerationDialog catalog={catalog} setFilters={setFilters}/>,
                roles: [ROLES.ADMIN]
            },
            {
                title: 'Comptabilisation',
                content: <ComptabilisationDialog filters={{class: 'straw'}}/>,
                roles: [ROLES.ADMIN, ROLES.ACCOUNTANT]
            },
        ];
    }, [catalog]);

    return (
        <>
            {!catalog.loading && (
                <Grid container spacing={3} className="Grid Grid-root">
                    <Grid item xs={8} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <HarvestYearFilter filters={filters}
                                               setFilters={setFilters}
                                               name={'harvestYear'}
                                               label={'Année contrat'}
                                               endYear={isGranted(ROLES.MEMBER) ? 2023 : 2021}
                                               stepYear={-1}
                                               optionNoneText={'Aucune'}
                            />
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <MemberFilter filters={filters}
                                              setFilters={setFilters}
                                />
                            </ProtectedComponent>
                        </Stack>
                    </Grid>
                    <Grid container item xs={4} justifyContent={'flex-end'} className="Grid">
                        <Stack spacing={1} direction={'row'}>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN]}>
                                <SenderMail typeSender={SENDER_MAIL.STRAW_INVOICES}/>
                                <MuiButton onClick={() => openDialog({
                                    fullWidth: true,
                                    maxWidth: 'md',
                                    children: <ExportDialog catalog={catalog}
                                                            isGranted={isGranted}
                                                            title={'Exporter une/des facture(s) paille'}/>
                                })}>
                                    Edition
                                </MuiButton>
                            </ProtectedComponent>
                            <ProtectedComponent acceptedRoles={[ROLES.ADMIN, ROLES.ACCOUNTANT]}>
                                <ImportMenuDialog buttonLabel={'Export'} data={importMenu}/>
                            </ProtectedComponent>
                            <ProtectedComponent acceptedRoles={['none']}> {/* CDL-794 */}
                                <RedirectButton to={`${location.pathname}/add`}>Ajouter une facture</RedirectButton>
                            </ProtectedComponent>
                        </Stack>
                    </Grid>
                </Grid>
            )}

            <MuiTableAsync
                columns={columns}
                onRowClick={(props) => navigate(props.id)}
                request={strawInvoiceService.getAllStrawInvoices}
                filters={filters}
                customPagination
            />
        </>
    )
}

export default StrawInvoices;