import {DataGrid, frFR, GridFooterContainer, GridPagination} from "@mui/x-data-grid";
import CustomPagination from "../DataGrid/CustomPagination";
import DeleteSelection from "../DataGrid/DeleteSelection";
import {useCallback, useEffect, useState} from "react";
import {hydraService} from "../../../utils/hydra";
import moment from "moment/moment";

const DataGridAsyncWrapper = ({
                                  columns,
                                  request,
                                  filters,
                                  onRowClick,
                                  onPageChange,
                                  customPagination,
                                  customDeleteRequest,
                                  customDeleteCallback,
                                  onSelectionModelChange = () => null,
                                  pageSize = 10,
                                  ...otherProps
                              }) => {

    if (!request) {
        throw new Error(`"request" must be a Promise et not be empty.`)
    }

    const [rowsState, setRowsState] = useState({
        rows: [],
        page: 0,
        rowCount: 0,
        pageSize: pageSize,
        loading: true,
    });

    const [order, setOrder] = useState({});

    const handleSortModelChange = useCallback((sortModel) => {
        let sorter = {};
        Array.from(sortModel).forEach(el => {
            sorter = {...sorter, [`order[${el['field']}]`]: el['sort']}
        });
        setOrder({...sorter, [`order[id]`]: 'asc'}); // Fix: sur le tri de colonne avec la même valeur string. CDL-757
    }, []);

    const CustomFooter = () => {
        return (
            <GridFooterContainer>
                <DeleteSelection request={customDeleteRequest} callback={customDeleteCallback}/>
                <CustomPagination/>
            </GridFooterContainer>
        );
    }

    const configDataGrid = {
        autoHeight: true,
        disableColumnFilter: true,
        paginationMode: 'server',
        sortingMode: 'server',
        onSortModelChange: handleSortModelChange,
        onSelectionModelChange: onSelectionModelChange,
        onPageChange: (page) => setRowsState((prevState) => ({...prevState, page})),
        ...(onRowClick && {onRowClick}),
        ...(onPageChange && {onPageChange}),
        ...(customDeleteRequest && {
            checkboxSelection: true,
            keepNonExistentRowsSelected: true
        }),
        ...(customPagination && {
            components: {
                Footer: CustomFooter,
            }
        }),
        ...otherProps
    }

    useEffect(() => {
        let active = true;
        setRowsState({
            ...rowsState,
            loading: true,
        });

        const timeOutId = setTimeout(() => {
            request({page: rowsState.page + 1, ...filters, ...order, timestamp: moment().unix()})
                .then((response) => {
                    if (!active) {
                        return;
                    }
                    const members = hydraService.getMembers(response.responseData);
                    const rowCount = hydraService.getTotalItems(response.responseData);
                    setRowsState({
                        ...rowsState,
                        rows: members.map(el => ({...el, id: el['@id']})),
                        rowCount,
                        loading: false,
                    });
                });
        }, 600);

        return () => {
            clearTimeout(timeOutId);
            active = false;
        }
    }, [rowsState.page, filters, order]);

    return (
        <div style={{height: otherProps.height ?? 'auto', width: '100%'}}>
            <DataGrid
                columns={columns}
                {...configDataGrid}
                {...rowsState}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />
        </div>
    );
}

export default DataGridAsyncWrapper;