import {run} from "../../utils/services";
import {invoiceService} from "../../services/invoice.service";
import DownloadStream from "../DownloadStream";

const DownloadInvoice = (number, callbacks = {}) => {
    return run(invoiceService.generateInline, {number}, callbacks)
        .then((response) => {
            DownloadStream(response.responseData, 'application/pdf', '', number);
        });
}

export default DownloadInvoice;