import React, {useCallback} from 'react';
import _ from "../../../utils/lodash/number/number_format";
import {MenuItem} from "@mui/material";
import MuiSelect from "../../Mui/Select";
import {useFilterContext} from "../../../context/FilterContext";

const ArticleTypeFilter = ({filters, setFilters, articleTypeList, ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';
    
    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, page: 1, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
        },
        [filters, setFilters]
    )

    return (
        <MuiSelect name={otherProps.name ?? 'articleType'}
                   label={otherProps.label ?? 'Type article'}
                   value={filters[otherProps.name ?? 'articleType'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {articleTypeList?.map((el, i) => <MenuItem key={i} value={el.code}>{el.label}</MenuItem>)}
        </MuiSelect>
    );
};

export default ArticleTypeFilter;
