import { createRef, useMemo, useRef, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { convertBase64 } from "../../utils/file";
import { LoadingButton } from "@mui/lab";
import { importService } from "../../services/import.service";
import snackBarWrapper from "../../components/SnackBar";

const ImportMenu = ({ data = [], callback = null }) => {
    const inputRef = createRef()
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const typeRef = useRef("");

    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const importMenu = useMemo(() => {
        return (data || []).map(i => ({
            title: i['title'],
            api: '',
            type: `${i['type'] ?? ""}`,
            disabled: !!i['disabled']
        }))
    }, [data])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (index = -1) => {
        if (index >= 0 && inputRef && inputRef.current) {
            typeRef.current = importMenu[index].type;
            inputRef.current.click();
        }
    };
    const handleSubmit = async (event) => {
        if (event.target.files && typeRef.current && !loading) {
            setLoading(true);
            const file = event.target.files[0];
            await convertBase64(file).then(async (base64) => {
                if (base64) {
                    const name = `${typeRef.current}.${`${file.name}`.split('.').pop()}`;
                    await importService.importFile({
                        type: typeRef.current,
                        file: {
                            name,
                            base64,
                        }
                    }).then(() => {
                        snackBarWrapper(`Votre import est en cours de traitement. Une pop-up s’ouvrira une fois celui-ci terminé. Un mail vous sera également envoyé.`);
                        if (callback && typeof callback === 'function') {
                            callback();
                        }
                    }).catch(() => {
                        snackBarWrapper(`Import ${typeRef.current} non effectué`, 'error');
                    });
                }
            });
            setLoading(false);
            typeRef.current = "";
            handleClose();
        }
        event.target.value="";
    }
    return (
        <>
            <input type="file" name="file" ref={inputRef} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{ display: 'none' }} onChange={handleSubmit} />
            <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={handleClick}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                Import
            </LoadingButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {importMenu.map((menu, index) => ([
                    <MenuItem key={menu.title}
                              disabled={menu.disabled ?? false}
                              onClick={() => handleClickMenu(index)}
                    >{menu.title}</MenuItem>
                ]))}
            </Menu>
        </>
    );
};

export default ImportMenu;