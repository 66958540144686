export const ENDPOINTS = {
  ROOT: "/",
  AUTHENTIFICATION: "/auth/admin",
  AUTO_CONNECTION: "/auth/member",
  AREA: "/areas",
  USER: "/users",
  ARTICLE: "/articles",
  ARTICLE_TYPE: "/article_types",
  ARTICLE_PRICE: "/article_prices",
  ARTICLE_YEARS: "/article_years",
  DEPOSITS: "/deposits",
  MEMBER: "/members",
  CATALOG: "/catalog",
  CATALOG_DELIVERIES_PLACES: "/catalog/deliveries/places",
  CATALOG_DELIVERIES_TYPES: "/catalog/deliveries/types",
  CATALOG_TRANSPORTS_TYPES: "/catalog/transports/types",
  CATALOG_AREAS_QUALITIES: "/catalog/areas/qualities",
  CATALOG_INVOICES_TYPES: "/catalog/invoices/types",
  CATALOG_EXPORT_INVOICES_TYPES: "/catalog/export/invoices/types",
  CATALOG_FARMINGS_TYPES: "/catalog/farmings/types",
  CATALOG_CONTRACT_TYPES: "/catalog/contracts/types",
  CATALOG_CONTRACT_QUALITY_TYPES: "/catalog/contracts/quality/types",
  CATALOG_PRODUCTION_LINES: "/catalog/production-lines",
  CAPITAL: "/capitals",
  CAPITAL_HISTORIES: "/capital_histories",
  INVOICE: "/invoice",
  INVOICE_SAGE: "/invoice/generate/sage",
  INVOICE_SAGE_EDITION: "/invoice/generate/sage/edition",
  INVOICE_INLINE: "/invoice/{number}/pdf",
  CHENEVIS: "/chenevis/tickets",
  CHENEVIS_INVOICE: "/chenevis/invoices",
  CHENEVIS_HUMIDITY: "/chenevis/humidities",
  CHENEVIS_KILOMETRIC_DRYER: "/chenevis/kilometric_allowance_dryer_silos",
  CHENEVIS_PAYMENT_CONDITIONS: "/chenevis/payment_conditions",
  CHENEVIS_COMPTABILISATION : "/comptabilisation/invoices",
  CONTRACT: "/contracts",
  DECLARATION_STRAW_HARVEST: "/declaration/straw/harvest",
  DECLARATION_STRAW_STOCK: "/declaration/straw/stock",
  CONTACT: "/contacts",
  VAT_CODE: "/vat_codes",
  DEPARTMENT: "/departments",
  SILO: "/silos",
  SILO_TYPES: "/catalog/silos/types",
  DUPLICATE_SILO: "/duplicate/silo-distance",
  SILO_DISTANCE: "/silo/distance",
  INVOICING_COMPANIES: '/invoicing_companies',
  EXPORT: "/export",
  EXPORT_CHENEVIS_INVOICE: "/export/chenevis/invoices",
  EXPORT_STRAW_INVOICE: "/export/straw/invoices",
  EXPORT_SUPPLY_INVOICE: "/export/supply/invoices",
  EXPORT_CHENEVIS_TICKET: "/export/chenevis/tickets",
  EXPORT_STRAW_TICKET: "/export/straw/tickets",
  EXPORT_SUPPLY_TICKET: "/export/supply/tickets",
  EXPORT_MEMBERS: "/export/members",
  STRAW: "/straw",
  STRAW_TICKET: `/straw/tickets`,
  STRAW_TICKET_FABRICATION: `/straw/tickets/fabrication-validate`,
  STRAW_TICKET_FABRICATION_VALIDATE: `/straw/tickets/fabrication/validate`,
  STRAW_UNIFORMITIES: `/straw/uniformities`,
  STRAW_BALL_WEIGHTS: `/straw/ball_weights`,
  STRAW_COLOR_MANUFACTURINGS: `/straw/color_manufacturings`,
  STRAW_POLLUTIONS: `/straw/pollutions`,
  STRAW_PRESENTATIONS: `/straw/presentations`,
  STRAW_LOADING_TIMES: `/straw/loading_times`,
  STRAW_HUMIDITIES: `/straw/humidities`,
  STRAW_PAYMENT_CONDITIONS: `/straw/payment_conditions`,
  STRAW_COLOR_DRIVERS: `/straw/color_drivers`,
  STRAW_PEBBLES: `/straw/pebbles`,
  DRIVER: "/drivers",
  STRAW_INVOICE: "/straw/invoices",
  SUPPLY_INVOICE: "/supply/invoices",
  SUPPLY_INVOICE_ARTICLES: "/supply_invoice_articles",
  SUPPLY: "/supply/payment_conditions",
  SUPPLY_TICKET: "/supply/tickets",
  SUPPLY_TICKET_ARTICLES: "/supply_ticket_articles",
  NOTVISIBLE_SUPPLY_TICKET: "/supply/tickets/visible",
  VALIDATE_SUPPLY_TICKET: "/supply/tickets/visible/validate",
  SEARCH_FILTER: "/search/filters",
  TITLE: "/titles",
  COLOR_DRIVER: "/straw/color_drivers",
  CHENEVIS_KILOMETRIC: "/chenevis/kilometric_allowances",
  SIMULATION: "/simulation/straw/balance",
  IMPORT_PLCD: "/import/plcd",
  IMPORT_PLCD_READ: "/import/plcd/read",
  IMPORT_VIVESCIA: "/import/vivescia",
  IMPORT_VIVESCIA_READ: "/import/vivescia/read",
  IMPORT_ALPIX: "/import/alpix",
  IMPORT_ALPIX_READ: "/import/alpix/read",
  IMPORT_TABLET: "/import/tablet",
  IMPORT_FILE: "/import/upload/file",
  TABLET_TICKET: "/tablet/tickets",
  FILE_REMOVE: "/file/remove",
  STRAW_SOCIAL_SHARES: "/straw/social_shares",
  SHARE_MOVEMENTS_ALL: "/share_movements",
  UPDATE_PASSWORD: "/update-password",
  RESET_PASSWORD: "/reset-password",
  SHARE_MOVEMENTS: '/members/share-movement/generate',
  NOTIFICATION: '/notification',
  ENGAGEMENTS: '/engagements',
  GENERATE_ENGAGEMENT: '/members/engagements/generate',
  SENDER_MAIL: '/senderMail/send'

};
