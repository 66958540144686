import React, {useCallback} from 'react';
import _ from "../../../utils/lodash/number/number_format";
import {MenuItem} from "@mui/material";
import MuiSelect from "../../Mui/Select";

const DepositFilter = ({filters, setFilters, depositList, ...otherProps}) => {
    const hasOptionNone = otherProps.hasOptionNone ?? true;
    const optionNoneText = otherProps.optionNoneText ?? 'Aucun';
    
    const handlerChange = useCallback(
        (e) => {
            const {name, value} = e.target;
            const newFilters = value ? {...filters, page: 1, [name]: value} : _.omit(filters, [name]);
            setFilters(newFilters);
        },
        [filters, setFilters]
    )

    return (
        <MuiSelect name={otherProps.name ?? 'deposit'}
                   label={otherProps.label ?? 'Dépôt'}
                   value={filters['deposit'] ?? ''}
                   onChange={handlerChange}
        >
            {hasOptionNone && <MenuItem value={''}><em>{optionNoneText}</em></MenuItem>}
            {depositList?.map((el, i) => <MenuItem key={i} value={el.name}>{el.name}</MenuItem>)}
        </MuiSelect>
    );
};

export default DepositFilter;
